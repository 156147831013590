import React, { useContext } from "react";
import { GlobalContext } from "../../App";
import useWindowDimensions from "../../hooks/getWindowDimensions";

//? COMPONENTS
import Slideshow from "./Slideshow";

//? STYLES & ICONS
import { Container, Title, SlideshowContainer, ImageContainer } from "./styles/PrototypeSC";

function Prototype({ images }) {
  const context = useContext(GlobalContext);
  const language = context.state.language;
  const { width } = useWindowDimensions();

  return (
    <Container>
      <Title>{language === "esp" ? <h2>Prototipo.</h2> : <h2>Prototype.</h2>}</Title>
      {width <= 768 ? (
        <SlideshowContainer>
          <Slideshow images={images} transitionTime={100} autoplay={false} arrowControls={true} dotControls={true} />
        </SlideshowContainer>
      ) : (
        <ImageContainer>
          {images.map((image, index) => (
            <img src={image} alt="" key={index} />
          ))}
        </ImageContainer>
      )}
    </Container>
  );
}

export default Prototype;
