import React, { useContext } from "react";
import { GlobalContext } from "../../App";
import useWindowDimensions from "../../hooks/getWindowDimensions";

//? COMPONENTS
import Slideshow from "./Slideshow";

//? STYLES & ICONS
import { Container, Title, SlideshowContainer, ImageContainer } from "./styles/ManualSC";

function Manual({ images }) {
  const context = useContext(GlobalContext);
  const language = context.state.language;
  const { width } = useWindowDimensions();

  return (
    <Container>
      <Title>
        <Title>{language === "esp" ? <h2>Manual de Uso.</h2> : <h2>User Manual.</h2>}</Title>
      </Title>
      {width <= 768 ? (
        <SlideshowContainer>
          <Slideshow images={images} transitionTime={100} autoplay={false} arrowControls={true} dotControls={true} />
        </SlideshowContainer>
      ) : (
        <ImageContainer>
          {images.map((image, index) => (
            <img src={image} alt="" key={index} />
          ))}
        </ImageContainer>
      )}
    </Container>
  );
}

export default Manual;
