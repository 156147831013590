import styled from "styled-components";
// import { iconRotate } from "./animations/ServiciosItem";
import { pulsate } from "../../styles/animations/NavbarLogoSC";
import { device } from "../../styles/device";

export const Container = styled.div`
  position: relative;
  @media screen and ${device.desktop} {
    width: 215px;
  }
  @media screen and ${device.laptop} {
    width: 190px;
  }
  @media screen and ${device.tablet} {
    width: 145px;
  }
  @media screen and ${device.mobile} {
    width: 215px;
  }

  font-weight: 700;
  font-family: var(--primaryFont);
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  & p {
    width: 100%;
    font-weight: 700;
    @media screen and ${device.desktop} {
      font-size: 16px;
    }
    @media screen and ${device.laptop} {
      font-size: 14px;
    }
    @media screen and ${device.tablet} {
      font-size: 11px;
    }
    @media screen and ${device.mobile} {
      font-size: 14px;
    }
    line-height: 19px;
    color: var(--darkGrey);

    margin: 0;
    margin-top: 10px;
  }
`;

export const Title = styled.div`
  @media screen and ${device.desktop} {
    height: 40px;
  }
  @media screen and ${device.mobile} {
    height: auto;
  }

  margin: 0;
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & h2 {
    font-weight: 900;
    @media screen and ${device.desktop} {
      font-size: 20px;
    }
    @media screen and ${device.laptop} {
      font-size: 18px;
    }
    @media screen and ${device.tablet} {
      font-size: 16px;
    }
    @media screen and ${device.mobile} {
      font-size: 14px;
    }
    line-height: 19px;
    color: var(--black);

    margin: 0;
  }
`;

export const Icon = styled.img`
  height: 40px;

  ${pulsate}
`;
