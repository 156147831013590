import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../App";

//? COMPONENTS
import Menu from "./Menu";

//? STYLES & ICONS
import { Container, Nav, NavContainer, Logo, LanguageSelector, MenuButton } from "./styles/NavbarSC";
import logo from "../images/iso.sinfondo.negro.png";
import { FaBars } from "react-icons/fa";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const context = useContext(GlobalContext);

  const showMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLanguage = e => {
    context.dispatch({ type: "CHANGELANGUAGE", payload: e.target.id });
  };

  return (
    <Container>
      <Nav>
        <NavContainer>
          <Link to="/">
            <Logo src={logo} alt="logo not available" />
          </Link>
        </NavContainer>
        <NavContainer></NavContainer>
        <NavContainer>
          <LanguageSelector>
            <p className={context.state.language === "esp" ? "lang-active" : "lang-inactive"} id="esp" onClick={handleLanguage}>
              ESP
            </p>
            <p className={context.state.language === "eng" ? "lang-active" : "lang-inactive"} id="eng" onClick={handleLanguage}>
              ENG
            </p>
          </LanguageSelector>
        </NavContainer>
        <NavContainer>
          <MenuButton>
            <FaBars onClick={showMenu} />
          </MenuButton>
        </NavContainer>
      </Nav>
      <Menu menuOpen={menuOpen} showMenu={showMenu} />
    </Container>
  );
}

export default Navbar;
