import React, { useContext } from "react";
import { GlobalContext } from "../../App";

//? COMPONENTS
import ServicioItem from "./ServicioItem";

//? STYLES & IMAGES
import { Container, ContainerImage, Title, GrayContainer } from "./styles/ServiciosSC";
import innovacion from "../../images/innovacion.png";
import diseño from "../../images/diseño.png";
import ingenieria from "../../images/ingenieria.png";
import uxui from "../../images/uxui.png";
import fabricacion from "../../images/fabricacion.png";

const servicios = [
  { title: "INNOVACION Y CREATIVIDAD", icon: innovacion, description: "Desarrollo de soluciones estéticas y funcionales." },
  { title: "DISEÑO INDUSTRIAL", icon: diseño, description: "Diseño y desarrollo de productos industriales de pequeña y gran escala." },
  {
    title: "INGENIERÍA DE PRODUCTO",
    icon: ingenieria,
    description: "Supervisamos y entregamos documentación detallada para producción. Planimetria 2D/3D.",
  },
  {
    title: "DISEÑO UX/UI",
    icon: uxui,
    description: "Diseño de experiencia de usuario y de interface para aplicaciones y paginas mobile/web.",
  },
  {
    title: "FABRICACIÓN",
    icon: fabricacion,
    description: "Gestión y seguimiento de fabricación del producto hasta su lanzamiento al mercado.",
  },
];
const services = [
  { title: "INNOVATION AND CREATIVITY", icon: innovacion, description: "Development of functional & suitable solutions." },
  { title: "INDUSTRIAL DESIGN", icon: diseño, description: "Design and development of small and large-scale industrial products." },
  {
    title: "PRODUCT ENGINEERING",
    icon: ingenieria,
    description: "We supervise and deliver detailed documentation for production. 2D/3D planimetry.",
  },
  {
    title: "UX/UI DESIGN",
    icon: uxui,
    description: "Design of user experience and interface for mobile/web applications .",
  },
  {
    title: "MANUFACTURING",
    icon: fabricacion,
    description: "Management and follow-up of product manufacturing until its launch.",
  },
];

function Servicios() {
  const context = useContext(GlobalContext);
  const language = context.state.language;

  return (
    <Container id={"servicios"}>
      <ContainerImage>
        <Title eng={language === "eng" ? true : false}>{language === "esp" ? <h3>¿A qué nos dedicamos?</h3> : <h3>What we do?</h3>}</Title>
      </ContainerImage>
      <GrayContainer>
        {language === "esp"
          ? servicios.map(servicio => <ServicioItem key={servicio.title} servicio={servicio} />)
          : services.map(service => <ServicioItem key={service.title} servicio={service} />)}
      </GrayContainer>
    </Container>
  );
}

export default Servicios;
