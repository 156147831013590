import styled from "styled-components";
import { device } from "../../styles/device";

export const Container = styled.div`
  position: relative;
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 4% 0 5%;
`;

export const Title = styled.div`
  width: 100%;

  & h2 {
    width: 100%;
    margin: 0;
    margin-bottom: 39px;

    color: var(--secondaryFontColor);
    font-weight: 700;
    @media screen and ${device.desktop} {
      margin-top: 40px;
      font-size: 45px;
      line-height: 62px;
    }
    @media screen and ${device.laptop} {
      margin-top: 40px;
      font-size: 38px;
      line-height: 51px;
    }
    @media screen and ${device.tablet} {
      margin-top: 0;
      font-size: 30px;
      line-height: 41px;
    }

    border-bottom: 1px solid var(--secondaryFontColor);
  }
`;

export const SlideshowContainer = styled.div`
  width: 213px;
  height: 350px;

  padding: 0;
  margin: 0;
  margin-bottom: 60px;
  box-sizing: border-box;

  max-width: 700px;
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 40px;

  & img {
    max-height: 500px;
    // max-width: 40vw;
    max-width: 22%;
    object-fit: cover;
  }
`;
