import styled from "styled-components";
import clientes from "../../../images/clientes.jpg";
import { device } from "../../styles/device";

export const Container = styled.div`
  position: relative;
  width: 100vw;
  @media screen and ${device.desktop} {
    height: 540px;
  }
  @media screen and ${device.laptop} {
    height: 420px;
  }
  @media screen and ${device.tablet} {
    height: 400px;
  }

  background-image: url(${clientes});
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: var(--primaryFont);
  text-align: center;
`;

export const Title = styled.div`
  width: 100%;
  text-align: center;

  margin: 0;
  @media screen and ${device.desktop} {
    margin-top: 100px;
    margin-bottom: 105px;
  }
  @media screen and ${device.laptop} {
    margin-top: 70px;
    margin-bottom: 84px;
  }
  @media screen and ${device.tablet} {
    margin-top: 42px;
    margin-bottom: 70px;
  }

  & h3 {
    margin: 0;
    font-weight: 900;
    line-height: 34px;
    color: var(--background);
    @media screen and ${device.desktop} {
      font-size: 63px;
    }
    @media screen and ${device.laptop} {
      font-size: 50px;
    }
    @media screen and ${device.tablet} {
      font-size: 45px;
    }
    @media screen and ${device.mobile} {
      font-size: 36px;
    }
  }
`;

export const ClientGrid = styled.div`
  width: 90%;

  display: grid;
  @media screen and ${device.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media screen and ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const ClientGridContainer = styled.div`
  @media screen and ${device.desktop} {
    height: 140px;
    width: 100%;
  }
  @media screen and ${device.laptop} {
    height: 120px;
    width: 100%;
  }
  @media screen and ${device.tablet} {
    height: 100px;
    width: 100%;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  & a {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & img {
    margin: 0;
    max-height: 100%;
    @media screen and ${device.desktop} {
      max-width: 100%;
    }
    @media screen and ${device.tablet} {
      max-width: 100%;
    }
  }
`;
