import styled from "styled-components";
import quienesSomos from "../../../images/quienesSomos.jpg";
import quienesSomos3 from "../../../images/quienesSomos3.jpg";
import { device } from "../../styles/device";

export const Container = styled.div`
  position: relative;
  width: 100vw;
  @media screen and ${device.desktop} {
    height: 800px;
  }
  @media screen and ${device.laptop} {
    height: 660px;
  }
  @media screen and ${device.tablet} {
    height: 570px;
  }
  @media screen and ${device.mobile} {
    height: 836px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FirstContainer = styled.div`
  position: relative;
  width: 100%;

  @media screen and ${device.desktop} {
    height: 376px;
  }
  @media screen and ${device.laptop} {
    height: 310px;
  }
  @media screen and ${device.tablet} {
    height: 270px;
  }
  @media screen and ${device.mobile} {
    height: 540px;
  }

  display: flex;
  @media screen and ${device.desktop} {
    flex-direction: row;
    justify-content: flex-start;
  }
  @media screen and ${device.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerImage = styled.div`
  left: 5vw;
  @media screen and ${device.desktop} {
    width: 44%;
    height: 100%;
  }
  @media screen and ${device.mobile} {
    width: 100%;
    height: 265px;
  }
  font-family: var(--primaryFont);
  text-align: center;

  background-image: url(${quienesSomos});
  background-size: cover;
  background-position: center;
`;

export const Title = styled.div`
  width: inherit;
  height: inherit;
  font-family: var(--primaryFont);

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  margin: 0;

  & h3 {
    overflow: hidden;
    margin: 0;
    @media screen and ${device.desktop} {
      padding: 0 20%;
      font-size: 75px;
      line-height: 95px;
    }
    @media screen and ${device.laptop} {
      padding: 0 20%;
      font-size: 65px;
      line-height: 85px;
    }
    @media screen and ${device.tablet} {
      margin: 0;
      font-size: 45px;
      line-height: 65px;
    }
    @media screen and ${device.mobile} {
      font-size: 55px;
      line-height: 75px;
    }

    font-weight: 900;
    color: #000;
    @media screen and (max-width: 350px) {
      line-height: 70px;
      ${props => (props.eng ? `font-size: 46px` : `font-size: 50px;`)}
    }
  }
`;

export const ContainerFoto = styled.div`
  position: relative;
  @media screen and ${device.desktop} {
    width: 56%;
    height: 100%;
  }

  @media screen and ${device.mobile} {
    width: 91%;
    height: 275px;
  }
`;

export const Foto = styled.img`
  width: 100%;
  @media screen and ${device.tablet} {
    height: 100%;
  }
  @media screen and ${device.mobile} {
    margin: 15px 0;
    height: 245px;
  }
  object-fit: cover;

  position: absolute;
  z-index: -10;
`;

export const ContainerButtons = styled.div`
  height: 75px;

  @media screen and ${device.desktop} {
    margin-top: 153px;
  }
  @media screen and ${device.laptop} {
    margin-top: 140px;
  }
  @media screen and ${device.tablet} {
    margin-top: 105px;
  }

  display: grid;
  grid-template-columns: 5% 32% 32% 1fr;
  gap: 0;
  grid-auto-flow: row;
  @media screen and (max-width: 350px) {
    grid-template-columns: 0% 32% 32% 1fr;
  }
`;

export const ContainerButton = styled.div`
  height: 100%;
`;

export const Logo = styled.img`
  height: 20px;
  background-color: var(--darkGreen);
  border-radius: 50%;
`;

export const ContainerImage2 = styled.div`
  left: 5vw;
  width: 100vw;
  @media screen and ${device.desktop} {
    height: 424px;
  }
  @media screen and ${device.laptop} {
    height: 350px;
  }
  @media screen and ${device.tablet} {
    height: 300px;
  }
  @media screen and ${device.mobile} {
    height: 295px;
  }

  font-family: var(--primaryFont);
  text-align: center;

  background-image: url(${quienesSomos3});
  background-size: cover;
  background-position: center;
`;

export const LogoBlack = styled.img`
  @media screen and ${device.desktop} {
    height: 150px;
    margin-top: 40px;
    margin-bottom: 0px;
  }
  @media screen and ${device.laptop} {
    height: 125px;
    margin-top: 37px;
    margin-bottom: 10px;
  }
  @media screen and ${device.tablet} {
    height: 94px;
    margin-top: 28px;
    margin-bottom: 10px;
  }
  @media screen and ${device.mobile} {
    height: 78px;
    margin-top: 23px;
    margin-bottom: 0px;
  }
`;

export const Text = styled.div`
  font-weight: 700;
  color: black;

  @media screen and ${device.desktop} {
    font-size: 55px;
    line-height: 75px;
  }
  @media screen and ${device.laptop} {
    font-size: 40px;
    line-height: 54px;
  }
  @media screen and ${device.tablet} {
    font-size: 30px;
    line-height: 41px;
  }
  @media screen and ${device.mobile} {
    font-size: 25px;
    line-height: 34px;
  }

  @media screen and (max-width: 350px) {
    font-size: 20px;
  }

  & p {
     @media screen and ${device.desktop} {
    }
    @media screen and ${device.laptop} {
    }
    @media screen and ${device.tablet} {
    }
    @media screen and ${device.mobile} {
    }
    margin: 0 5%;
  
    }
  }
`;
