import React, { useContext } from "react";
import { GlobalContext } from "../../App";
import useWindowDimensions from "../../hooks/getWindowDimensions";

//? COMPONENTS
import Slideshow from "./Slideshow";

//? STYLES & ICONS
import { Container, Title, SlideshowContainer, ImageContainer } from "../projects/styles/OtherProjectsSC";

function OtherProjects({ images, links }) {
  const context = useContext(GlobalContext);
  const language = context.state.language;
  const { width } = useWindowDimensions();

  return (
    <Container>
      <Title>{language === "esp" ? <h2>Proyectos</h2> : <h2>Projects</h2>}</Title>
      {width <= 768 ? (
        <SlideshowContainer>
          <Slideshow images={images} links={links} transitionTime={100} autoplay={false} arrowControls={true} dotControls={true} />
        </SlideshowContainer>
      ) : (
        <ImageContainer>
          {images.map((project, index) => (
            <a href={project.link} key={index}>
              <img src={project.image} alt="" />
            </a>
          ))}
        </ImageContainer>
      )}
    </Container>
  );
}

export default OtherProjects;
