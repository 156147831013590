import styled from "styled-components";
import servicios from "../../../images/servicios.jpg";
import { device } from "../../styles/device";

export const Container = styled.div`
  position: relative;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerImage = styled.div`
  left: 5vw;
  width: 100vw;
  @media screen and ${device.desktop} {
    height: 400px;
  }
  @media screen and ${device.laptop} {
    height: 350px;
  }
  @media screen and ${device.tablet} {
    height: 300px;
  }
  @media screen and ${device.mobile} {
    height: 300px;
  }
  font-family: var(--primaryFont);
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${servicios});
  background-size: cover;
  background-position: center;
`;

export const Title = styled.div`
  width: 100%;
  font-family: var(--primaryFont);
  text-align: center;

  & h3 {
    font-weight: 900;
    color: var(--background);
    margin: 0;
    line-height: 75px;

    @media screen and ${device.desktop} {
      font-size: 65px;
    }
    @media screen and ${device.laptop} {
      font-size: 55px;
    }

    @media screen and (max-width: 400px) {
      font-size: 50px;
    }
    @media screen and (max-width: 350px) {
      ${props => (props.eng ? `font-size:42px;` : `font-size:46px;`)}
    }
  }
`;

export const GrayContainer = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  @media screen and ${device.desktop} {
    margin-top: 85px;
    height: 280px;
  }
  @media screen and ${device.laptop} {
    margin-top: 65px;
    height: 260px;
  }
  @media screen and ${device.tablet} {
    margin-top: 50px;
    height: 215px;
  }
  @media screen and ${device.mobile} {
    height: 915px;
  }

  display: flex;
  @media screen and ${device.desktop} {
    flex-direction: row;
    justify-content: space-around;
  }
  @media screen and ${device.mobile} {
    flex-direction: column;
    align-items: center;
  }
  justify-content: space-evenly;
`;
