import React from "react";
import useWindowDimensions from "../../hooks/getWindowDimensions";

//?COMPONENTS
import Slideshow from "./Slideshow";
import SlideshowLandscape from "./SlideshowLandscape";

//? STYLES
import { ContainerSlide } from "./styles/IntroSC";

function Intro() {
  const { width } = useWindowDimensions();

  return (
    <ContainerSlide id="nuestra-mision">
      {width < 450 ? (
        <Slideshow transitionTime={200} autoplay={false} autoplayTime={3000} arrowControls={true} dotControls={true} />
      ) : (
        <SlideshowLandscape transitionTime={200} autoplay={false} autoplayTime={3000} arrowControls={true} dotControls={true} />
      )}
    </ContainerSlide>
  );
}

export default Intro;
