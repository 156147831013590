import styled from "styled-components";
import { device } from "../../styles/device";

export const Form = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  & input[type="text"] {
    margin-top: 15px;
    width: 90%;
    height: 31px;
    box-sizing: border-box;

    background-color: var(--lightGrey);
    border: none;
    border-radius: 9999px;

    text-align: center;

    &::placeholder {
      font-weight: 700;
      @media screen and ${device.desktop} {
        font-size: 18px;
        line-height: 21px;
      }
      @media screen and ${device.laptop} {
        font-size: 15px;
        line-height: 18px;
      }
      @media screen and ${device.tablet} {
        font-size: 12px;
        line-height: 14px;
      }
    }

    &:focus {
      background-color: var(--darkGrey);
      outline: none;
    }
  }

  & input[type="text"]:first-child {
    text-transform: capitalize;
  }

  & textarea {
    margin-top: 15px;
    width: 90%;
    height: 105px;
    box-sizing: border-box;
    resize: none;

    background-color: var(--lightGrey);
    border: none;
    border-radius: 15px;

    padding: 7px;
    text-align: center;

    &::placeholder {
      font-weight: 700;
      @media screen and ${device.desktop} {
        font-size: 18px;
        line-height: 21px;
      }
      @media screen and ${device.laptop} {
        font-size: 15px;
        line-height: 18px;
      }
      @media screen and ${device.tablet} {
        font-size: 12px;
        line-height: 14px;
      }
      padding-top: 35px;
    }

    &:focus {
      background-color: var(--darkGrey);
      outline: none;
    }
  }
`;

export const SubmitButton = styled.input`
  margin-top: 15px;
  @media screen and ${device.desktop} {
    width: 165px;
    height: 42px;
  }
  @media screen and ${device.laptop} {
    width: 137px;
    height: 35px;
  }
  @media screen and ${device.tablet} {
    width: 110px;
    height: 28px;
  }

  ${props => (props.disabled ? `background-color: var(--darkGrey);` : `background-color: var(--buttonGreen);`)}
  border: none;
  border-radius: 9999px;

  text-align: center;
  color: var(--white);
  font-weight: 700;
  @media screen and ${device.desktop} {
    font-size: 18px;
    line-height: 24px;
  }
  @media screen and ${device.laptop} {
    font-size: 15px;
    line-height: 20px;
  }
  @media screen and ${device.tablet} {
    font-size: 12px;
    line-height: 16px;
  }

  cursor: pointer;
`;
