import React, { useState, useContext } from "react";
import { GlobalContext } from "../../App";
import { send } from "emailjs-com";

//? COMPONENTS
import ConfirmPopUp from "./ConfirmPopUp";

//? STYLES & IMAGES & ICONS
import { Form, SubmitButton } from "./styles/MailFormSC";

function MailForm() {
  const context = useContext(GlobalContext);
  const language = context.state.language;

  const [popUp, setPopUp] = useState(false);
  const [sendResult, setSendResult] = useState("notSent");

  const [contact, setContact] = useState({ name: "", email: "", message: "" });

  const handleChange = e => {
    e.preventDefault();
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  //*Functions to handle confirm popup
  const confirmPopUp = e => {
    e.preventDefault();
    setPopUp(true);
  };
  function cancelPopUp(e) {
    e.preventDefault();
    setPopUp(false);
    setSendResult("notSent");
  }

  const handleSubmit = e => {
    e.preventDefault();
    setSendResult("loading");

    send("contact_service", "contact_form", contact, process.env.REACT_APP_USER_ID)
      .then(response => {
        setSendResult("succes");
        console.log("SUCCESS!", response.status, response.text);
        setContact({ name: "", email: "", message: "" });
      })
      .catch(err => {
        setSendResult("error");
        console.log("FAILED...", err);
      });
  };

  return (
    <>
      <Form onSubmit={confirmPopUp}>
        <input type="text" placeholder={language === "esp" ? "Nombre" : "Name"} name="name" onChange={handleChange} value={contact.name} />
        <input type="text" placeholder="Mail" name="email" onChange={handleChange} value={contact.email} />
        <textarea
          placeholder={language === "esp" ? "¿En que podemos ayudar?" : "How can we help?"}
          name="message"
          onChange={handleChange}
          value={contact.message}
        />
        <SubmitButton
          type="submit"
          disabled={contact.name && contact.email && contact.message ? false : true}
          value={language === "esp" ? "ENVIAR" : "SEND"}
        />
      </Form>
      {popUp && (
        <ConfirmPopUp
          text={language === "esp" ? "¿Enviamos el mail?" : "Do we send the mail?"}
          cancelText="Cancelar"
          sendResult={sendResult}
          aceptPopUp={handleSubmit}
          cancelPopUp={cancelPopUp}
        />
      )}
    </>
  );
}

export default MailForm;
