import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 90%;
  height: 100%;

  font-family: var(--primaryFont);

  & img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & div {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 900;
    font-size: 30px;
    line-height: 41px;
    color: rgb(235, 235, 235, 0);
    text-align: center;

    &:hover {
      color: #fff;
      background-color: #000;
      opacity: 0.8;
      cursor: pointer;
    }
  }

  &:hover {
    background-color: #000;
    opacity: 0.8;
  }
`;
