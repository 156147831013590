export const initialState = {
  language: "esp",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGELANGUAGE":
      return { ...state, language: action.payload };
    default:
      return state;
  }
};
