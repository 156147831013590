import styled from "styled-components";
import { device } from "../../styles/device";

export const Container = styled.div`
  @media screen and ${device.desktop} {
    width: 143px;
    height: 68px;
  }
  @media screen and ${device.laptop} {
    width: 120px;
    height: 57px;
  }
  @media screen and ${device.tablet} {
    width: 110px;
    height: 52px;
  }

  color: var(--background);
`;

export const Name = styled.p`
  width: 100%;
  margin: 0px;
  @media screen and ${device.desktop} {
    height: 26px;
    font-size: 14px;
    line-height: 26px;
  }
  @media screen and ${device.laptop} {
    height: 22px;
    font-size: 12px;
    line-height: 22px;
  }
  @media screen and ${device.tablet} {
    height: 20px;
    font-size: 11px;
    line-height: 20px;
  }

  background-color: var(--darkGreen);
  text-align: center;

  color: var(--background);
  font-weight: 700;
`;

export const Description = styled.p`
  width: 100%;
  @media screen and ${device.desktop} {
    height: 42px;
    padding-top: 10px;
    font-size: 12px;
    line-height: 16px;
  }
  @media screen and ${device.laptop} {
    height: 35px;
    padding-top: 8px;
    font-size: 10px;
    line-height: 14px;
  }
  @media screen and ${device.tablet} {
    height: 32px;
    padding-top: 7px;
    font-size: 9px;
    line-height: 12.5px;
  }
  margin: 0px;

  background-color: var(--background);
  text-align: center;

  color: black;
  font-weight: 400;
`;
