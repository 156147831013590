import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

//?STYLES & ICONS & IMAGES
import { Image, Container, SlideContainer, Slide, Controls, Button, DotControls, DotButton } from "./styles/SlideshowSC";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

function Slideshow({ transitionTime, autoplay, autoplayTime, arrowControls, dotControls, images, links }) {
  const slideshow = useRef(null);
  const intervalSlideshow = useRef(null);
  const [imageIndex, setImageIndex] = useState(1);

  //?ARROW CONTROLS
  const next = () => {
    //Comprobamos que el slideshow tenga elementos
    if (slideshow.current.children.length > 0) {
      if (imageIndex < slideshow.current.children.length) {
        //Obtenemos el primer elemento del slideshow
        const firstElement = slideshow.current.children[0];
        //Establecemos la transición para el slideshow
        slideshow.current.style.transition = `${transitionTime}ms ease-out all`;
        //Movemos el slideshow hacia la izquierda
        slideshow.current.style.transform = `translateX(-${firstElement.clientWidth}px)`;

        //Añadimos un listener para que cuando termine la transición, se elimine el listener y se ejecute la función transition
        const transition = () => {
          //Reiniciamos la posición del slideshow
          slideshow.current.style.transition = "none";
          slideshow.current.style.transform = "translateX(0px)";
          //Enviamos el primer elemento al final
          slideshow.current.appendChild(firstElement);
          //Eliminamos el listener
          slideshow.current.removeEventListener("transitionend", transition);
        };
        slideshow.current.addEventListener("transitionend", transition);

        //Cambiamos el índice de la imagen
        if (imageIndex === images.length) {
          setImageIndex(1);
        } else {
          setImageIndex(imageIndex + 1);
        }
      }
    }
  };

  const previous = () => {
    if (imageIndex > 1) {
      if (slideshow.current.children.length > 0) {
        const lastElement = slideshow.current.children[slideshow.current.children.length - 1];
        slideshow.current.insertBefore(lastElement, slideshow.current.firstChild);

        slideshow.current.style.transition = "none";
        slideshow.current.style.transform = `translateX(-${lastElement.clientWidth}px)`;

        setTimeout(() => {
          slideshow.current.style.transition = `${transitionTime}ms ease-out all`;
          slideshow.current.style.transform = "translateX(0px)";
        }, transitionTime / 10);
      }

      if (imageIndex === 1) {
        setImageIndex(images.length);
      } else {
        setImageIndex(imageIndex - 1);
      }
    }
  };

  //?DOT CONTROLS
  const dotControl = dot => {
    if (slideshow.current.children.length > 0) {
      const elements = slideshow.current.children;

      if (dot > imageIndex) {
        slideshow.current.style.transition = `${transitionTime}ms ease-out all`;
        slideshow.current.style.transform = `translateX(-${elements[0].clientWidth * (dot - imageIndex)}px)`;

        const transition = () => {
          slideshow.current.style.transition = "none";
          slideshow.current.style.transform = "translateX(0px)";
          for (let i = 0; i < dot - imageIndex; i++) {
            slideshow.current.appendChild(elements[0]);
          }
          slideshow.current.removeEventListener("transitionend", transition);
        };
        slideshow.current.addEventListener("transitionend", transition);
      } else if (dot < imageIndex) {
        for (let i = 0; i < imageIndex - dot; i++) {
          slideshow.current.insertBefore(elements[elements.length - 1], slideshow.current.firstChild);
        }

        slideshow.current.style.transition = "none";
        slideshow.current.style.transform = `translateX(-${elements[elements.length - 1].clientWidth * (imageIndex - dot)}px)`;

        setTimeout(() => {
          slideshow.current.style.transition = `${transitionTime}ms ease-out all`;
          slideshow.current.style.transform = "translateX(0px)";
        }, transitionTime / 10);
      }
    }
    setImageIndex(dot);
  };

  //?AUTO-PLAY
  useEffect(() => {
    if (autoplay) {
      intervalSlideshow.current = setInterval(() => {
        next();
      }, autoplayTime);

      //Eliminar el intervalo
      slideshow.current.addEventListener("mouseenter", () => {
        clearInterval(intervalSlideshow.current);
      });

      //Reanudamos el intervalo
      slideshow.current.addEventListener("mouseleave", () => {
        intervalSlideshow.current = setInterval(() => {
          next();
        }, autoplayTime);
      });
    }
    //eslint-disable-next-line
  }, []);

  //?SWIPE
  const swipeConfig = {
    delta: 2,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true,
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      next();
    },
    onSwipedRight: () => {
      previous();
    },
    ...swipeConfig,
  });

  return (
    <Container {...swipeHandlers}>
      <SlideContainer ref={slideshow}>
        {images.map((image, index) => {
          return links ? (
            <Slide key={index}>
              <Link to={image.link}>
                <Image src={image.image} alt="" />
              </Link>
            </Slide>
          ) : (
            <Slide key={index}>
              <Image src={image} alt="" />
            </Slide>
          );
        })}
      </SlideContainer>
      {arrowControls && (
        <Controls>
          <Button onClick={previous} active={imageIndex === 1 ? false : true}>
            <FiArrowLeft />
          </Button>
          <Button right onClick={next} active={slideshow.current && imageIndex === slideshow.current.children.length ? false : true}>
            <FiArrowRight />
          </Button>
        </Controls>
      )}
      {dotControls && (
        <DotControls>
          {images.map((_image, index) => {
            return <DotButton key={index + 1} active={index + 1 === imageIndex ? true : false} onClick={() => dotControl(index + 1)} />;
          })}
        </DotControls>
      )}
    </Container>
  );
}

export default Slideshow;
