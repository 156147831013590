import React, { useContext } from "react";
import { GlobalContext } from "../App";

//? COMPONENTS
import MailForm from "./footer/MailForm";

//? STYLES & IMAGES & ICONS
import { SuperContainer, Container, Redes, ContainerMail, ContainerOther, Credits } from "./styles/FooterSC";
import wsp from "../images/wsp.png";
import { FaInstagram, FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import logo from "../images/iso.sinfondo.b.png";

function Footer() {
  const context = useContext(GlobalContext);
  const language = context.state.language;

  return (
    <SuperContainer id="footer">
      <Container>
        <ContainerMail>
          {language === "esp" ? <h1> HACENOS TU CONSULTA</h1> : <h1>CONTACT US</h1>}
          <MailForm />
        </ContainerMail>
        <ContainerOther>
          {language === "esp" ? <h1>Escribinos</h1> : <h1>Talk to us</h1>}
          <a href={`https://wa.me/5493416820296/`} target="_blank" rel="noreferrer">
            <img src={wsp} alt="whatsapp" id="wsp" />
          </a>
          {language === "esp" ? <h1>Seguinos</h1> : <h1>Follow us</h1>}
          <Redes>
            <a href="https://www.instagram.com/irupe.id/" target="_blank" rel="noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.facebook.com/Irup%C3%A9-m%C3%A1s-dise%C3%B1o-101426445814739" target="_blank" rel="noreferrer">
              <FaFacebookSquare />
            </a>
            <a href="https://www.linkedin.com/company/irup%C3%A9-m%C3%A1s-dise%C3%B1o" target="_blank" rel="noreferrer">
              <FaLinkedin />
            </a>
          </Redes>
          <img src={logo} alt="logo" id="logo" />
          <p>irupe.id@gmail.com</p>
        </ContainerOther>
      </Container>
      <Credits>Developed by Franco Panvini & Nicolas Militello. 2022 ©</Credits>
    </SuperContainer>
  );
}

export default Footer;
