import React from "react";

//? COMPONENTS
import Intro from "./home/Intro";
import NuestraMision from "./home/NuestraMision";
import Proyectos from "./home/Proyectos";
import Servicios from "./home/Servicios";
import QuienesSomos from "./home/QuienesSomos";
import Clientes from "./home/Clientes";

//? STYLES & ICONS
import { Container } from "./styles/HomeSC";

function Home() {
  return (
    <Container>
      <Intro />
      <NuestraMision />
      <Proyectos />
      <Servicios />
      <QuienesSomos />
      <Clientes />
    </Container>
  );
}

export default Home;
