import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../App";

//? COMPONENTS
import ImageContainer from "./ImageContainer";

//? STYLES & IMAGES
import { Container, LinkContainer, Title } from "./styles/ProyectosSC";
import totem from "../../images/totem/Totem.jpg";
import chalten from "../../images/Chalten.png";

function Proyectos() {
  const context = useContext(GlobalContext);
  const language = context.state.language;

  return (
    <Container id={"proyectos"}>
      <Title>{language === "esp" ? <h3>PROYECTOS</h3> : <h3>PROJECTS</h3>}</Title>
      <LinkContainer>
        <Link to="/totem">
          <ImageContainer imageURL={totem} title={"TOTEM"} />
        </Link>
        <Link to="/chalten">
          <ImageContainer imageURL={chalten} title={"CHALTEN"} />
        </Link>
      </LinkContainer>
    </Container>
  );
}

export default Proyectos;
