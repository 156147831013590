import React, { useContext } from "react";
import { HashLink } from "react-router-hash-link";
import { GlobalContext } from "../App";

//? STYLES & ICONS
import { Container } from "./styles/MenuSC";

function Menu({ menuOpen, showMenu }) {
  const context = useContext(GlobalContext);
  const language = context.state.language;

  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -40;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <Container className={menuOpen ? "menu-active" : "menu-inactive"}>
      <HashLink smooth to={"/#nuestra-mision"} onClick={showMenu} scroll={el => scrollWithOffset(el)} style={{ textDecoration: "none" }}>
        {language === "esp" ? <p>IRUPÉ</p> : <p>IRUPÉ</p>}
      </HashLink>
      <p>-</p>
      <HashLink smooth to={"/#proyectos"} onClick={showMenu} scroll={el => scrollWithOffset(el)} style={{ textDecoration: "none" }}>
        {language === "esp" ? <p>PROYECTOS</p> : <p>PROJECTS</p>}
      </HashLink>
      <p>-</p>
      <HashLink smooth to={"/#servicios"} onClick={showMenu} scroll={el => scrollWithOffset(el)} style={{ textDecoration: "none" }}>
        {language === "esp" ? <p>A QUÉ NOS DEDICAMOS</p> : <p>WHAT DO WE DO</p>}
      </HashLink>
      <p>-</p>
      <HashLink smooth to={"/#quienes-somos"} onClick={showMenu} scroll={el => scrollWithOffset(el)} style={{ textDecoration: "none" }}>
        {language === "esp" ? <p>QUIÉNES SOMOS </p> : <p>ABOUT US</p>}
      </HashLink>
      <p>-</p>
      <HashLink smooth to={"/#clientes"} onClick={showMenu} scroll={el => scrollWithOffset(el)} style={{ textDecoration: "none" }}>
        {language === "esp" ? <p>CLIENTES</p> : <p>CLIENTS</p>}
      </HashLink>
      <p>-</p>
      <HashLink smooth to={"#footer"} onClick={showMenu} scroll={el => scrollWithOffset(el)} style={{ textDecoration: "none" }}>
        {language === "esp" ? <p>CONTACTO</p> : <p>CONTACT</p>}
      </HashLink>
    </Container>
  );
}

export default Menu;
