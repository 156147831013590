import React, { useContext } from "react";
import { GlobalContext } from "../../App";

//? STYLES & ICONS
import { Container, Title, Info, Description, Images } from "./styles/IntroSC";

function Intro({ title, product, client, description, clientLogo, images }) {
  const context = useContext(GlobalContext);
  const language = context.state.language;

  return (
    <Container>
      <Title>
        <h1>{title}</h1>
      </Title>
      <Info>
        <h5>
          {language === "esp" ? "Producto:" : "Product:"} {product}
        </h5>
        <h5>
          {language === "esp" ? "Cliente:" : "Client:"} {client}
        </h5>
      </Info>
      <Description>
        <p>{description}</p>
      </Description>
      <Images>
        {images.map((image, index) => (
          <img src={image} alt="not available" key={index} />
        ))}
      </Images>
    </Container>
  );
}

export default Intro;
