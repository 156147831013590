import React, { useContext } from "react";
import { GlobalContext } from "../../App";

//? STYLES & ICONS
import { Container, PopUp, Loader, Button, Text, ContainerButton } from "./styles/ConfirmPopUpSC";
import { AiOutlineSend } from "react-icons/ai";
import { ImCancelCircle } from "react-icons/im";
import { BiCheckCircle } from "react-icons/bi";

function ConfirmPopUp({ text, sendResult, aceptPopUp, cancelPopUp }) {
  const context = useContext(GlobalContext);
  const language = context.state.language;

  return (
    <Container>
      <PopUp>
        {sendResult === "notSent" && (
          <>
            <Text>{text}</Text>
            <ContainerButton>
              <Button onClick={aceptPopUp}>
                <AiOutlineSend />
              </Button>
              <Button cancel onClick={cancelPopUp}>
                <ImCancelCircle />
              </Button>
            </ContainerButton>
          </>
        )}
        {sendResult === "loading" && (
          <Loader>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </Loader>
        )}
        {sendResult === "succes" && (
          <>
            <Text>{language === "esp" ? "Gracias!! Recibimos tu msj" : "Thanks!! We got your message"} </Text>
            <ContainerButton>
              <Button onClick={cancelPopUp}>
                <BiCheckCircle />
              </Button>
            </ContainerButton>
          </>
        )}
        {sendResult === "error" && (
          <>
            <Text>{language === "esp" ? "Oops!! Volvé a intentarlo!!" : "Oops!! Try it again!!"}</Text>
            <ContainerButton>
              <Button cancel onClick={cancelPopUp}>
                <ImCancelCircle />
              </Button>
            </ContainerButton>
          </>
        )}
      </PopUp>
    </Container>
  );
}

export default ConfirmPopUp;
