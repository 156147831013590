import styled from "styled-components";
import { device } from "../../styles/device";

export const Container = styled.div`
  position: relative;
  width: 100vw;
  @media screen and ${device.desktop} {
    height: 650px;
  }
  @media screen and ${device.laptop} {
    height: 500px;
  }
  @media screen and ${device.mobile} {
    height: 828px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LinkContainer = styled.div`
  display: flex;
  @media screen and ${device.desktop} {
    flex-direction: row;
    justify-content: space-around;
  }
  @media screen and ${device.mobile} {
    flex-direction: column;
    align-items: center;
  }
  width: 100vw;

  & a {
    @media screen and ${device.desktop} {
      width: 420px;
      height: 420px;
    }
    @media screen and ${device.laptop} {
      width: 320px;
      height: 320px;
    }

    margin-top: 32px;
    display: flex;
    justify-content: center;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-family: var(--primaryFont);
  text-align: center;

  margin-top: 43px;

  & h3 {
    width: 100%;
    overflow: hidden;
    font-weight: 900;
    color: #000;
    margin: 0;

    @media screen and ${device.desktop} {
      font-size: 70px;
    }
    @media screen and ${device.laptop} {
      font-size: 55px;
    }
    @media screen and ${device.tablet} {
      font-size: 50px;
    }
    @media screen and ${device.mobile} {
      font-size: 40px;
    }
  }
`;
