import styled from "styled-components";

export const Image = styled.img`
  width: 100%;
  height: 100%;
  vertical-align: top;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SlideContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
`;

export const Slide = styled.div`
  min-width: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 10;
  position: relative;
`;

export const TextImage2 = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: 46%;

  background-color: rgb(0, 0, 0, 0.9);

  color: var(--background);
  font-weight: 700;
  & h1 {
    width: 92%;
    margin: 4% 7% 0 7%;
    line-height: 44px;
    ${props => (props.eng ? `font-size:34px;` : `font-size:30px;`)}

    @media screen and (max-width: 400px) {
      margin-top: 3%;
      font-size: 30px;
    }
    @media screen and (max-width: 350px) {
      margin-top: 2%;
      font-size: 26px;
      line-height: 40px;
    }
  }
`;

export const TextImage3 = styled.div`
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 54%;

  background-color: rgb(0, 0, 0, 0.9);

  color: var(--background);
  font-weight: 700;
  & h1 {
    width: 92%;
    margin: 10% 4% 0 4%;
    line-height: 44px;
    font-size: 32px;
    @media screen and (max-width: 410px) {
      font-size: 28px;
    }
    @media screen and (max-width: 360px) {
      margin-top: 6%;
      font-size: 24px;
    }
    @media screen and (max-width: 310px) {
      margin-top: 6%;
      font-size: 18px;
      line-height: 36px;
    }
  }
`;

export const TextImage4 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;

  color: #000;
  font-weight: 700;
  & h1 {
    width: 80%;
    margin: 26% 10% 0 10%;
    line-height: 44px;
    font-size: 32px;
    @media screen and (max-width: 400px) {
      font-size: 28px;
    }
    @media screen and (max-width: 350px) {
      margin-top: 20%;
      font-size: 24px;
    }
  }
`;

export const Image4 = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 39%;
`;

export const TextImage5 = styled.div`
  position: absolute;
  top: 8%;
  left: 8%;
  z-index: 10;
  width: 84%;
  height: 84%;

  background-color: rgb(0, 0, 0, 0.9);

  color: var(--background);
  font-weight: 700;
  & h1 {
    width: 88%;
    margin: 12% 6% 0 6%;
    text-align: center;

    line-height: 45px;
    ${props => (props.eng ? `font-size:25px; margin: 20% 6% 0 6%;` : `font-size:25px;`)}
    @media screen and (max-width: 400px) {
      ${props => (props.eng ? `font-size:23px;` : `font-size:20px; line-height: 40px; margin: 17% 6% 0 6%;`)}
    }
    @media screen and (max-width: 350px) {
      ${props => (props.eng ? `font-size:20px; margin: 15% 6% 0 6%;` : `font-size:16px; line-height: 32px; margin: 20% 6% 0 6%;`)}
    }
    @media screen and (max-width: 280px) {
      ${props => (props.eng ? `font-size:18px; margin: 15% 6% 0 6%;` : `font-size:16px; line-height: 32px; margin: 7% 6% 0 6%;`)}
    }
  }
`;

export const Controls = styled.div`
  position: absolute;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const Button = styled.button`
  pointer-events: all;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  padding: 0;
  width: 10%;
  height: 100%;

  font-size: 2.5rem;
  color: var(--lightGreen);

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s ease;
  position: absolute;
  ${props => (props.right ? `right: 0;` : `left: 0;`)}
`;

export const DotControls = styled.div`
  position: absolute;
  z-index: 20;
  width: 100%;
  pointer-events: none;

  top: 95%;

  display: flex;
  justify-content: center;
`;

export const DotButton = styled.button`
  pointer-events: all;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 1px solid var(--darkGrey);
  cursor: pointer;
  outline: none;

  background: ${props => (props.active ? "var(--lightGreen)" : "var(--lightGrey)")};
  border: ${props => (props.active ? "none" : "1px solid var(--darkGrey)")};

  padding: 0;
  margin: 0 5px;
`;
