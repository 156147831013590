import styled from "styled-components";
import { device } from "../styles/device";

export const Container = styled.div`
  position: relative;
  @media screen and ${device.desktop} {
    top: 60px;
  }
  @media screen and ${device.tablet} {
    top: 45px;
  }
  width: 100vw;

  display: flex;
  flex-direction: column;
`;
