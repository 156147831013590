import styled from "styled-components";
import { device } from "../../styles/device";

export const BackgroundContainer = styled.div`
  width: 100%;
  background-color: var(--black);
`;

export const Container = styled.div`
  position: relative;
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 4% 0 5%;
`;

export const Title = styled.div`
  width: 100%;

  & h2 {
    width: 100%;
    margin: 0;
    margin-bottom: 49px;

    color: white;
    font-weight: 700;
    @media screen and ${device.desktop} {
      margin-top: 40px;
      font-size: 45px;
      line-height: 62px;
    }
    @media screen and ${device.laptop} {
      margin-top: 40px;
      font-size: 38px;
      line-height: 51px;
    }
    @media screen and ${device.tablet} {
      margin-top: 24px;
      font-size: 30px;
      line-height: 41px;
    }

    border-bottom: 1px solid white;
  }
`;

export const SlideshowContainer = styled.div`
  width: 350px;
  height: 520px;

  padding: 0;
  margin: 0;
  margin-bottom: 30px;
  box-sizing: border-box;

  max-width: 700px;
  overflow: hidden;

  @media screen and (max-width: 350px) {
    width: 300px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 40px;
  & img {
    max-height: 400px;
    object-fit: cover;
    max-width: 22%;
  }
`;
