import styled from "styled-components";
import { device } from "../styles/device";

export const SuperContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  position: relative;
  width: 100vw;

  @media screen and ${device.desktop} {
    top: 60px;
    height: 600px;
  }
  @media screen and ${device.tablet} {
    top: 45px;
    bottom: 0;
    height: 770px;
  }

  background: linear-gradient(180deg, var(--black) 0%, rgba(255, 255, 255, 0) 100%), var(--darkGrey);

  display: flex;
  @media screen and ${device.desktop} {
    flex-direction: row;
  }
  @media screen and ${device.tablet} {
    flex-direction: column;
  }
`;

export const ContainerMail = styled.div`
  @media screen and ${device.desktop} {
    height: 340px;
    width: 50%;
    margin: 130px 0;
  }
  @media screen and ${device.tablet} {
    height: auto;
    width: 100%;
    margin: 0;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  & h1 {
    margin: 0;
    margin-top: 35px;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    color: #fff;
  }
`;
export const ContainerOther = styled.div`
  @media screen and ${device.desktop} {
    margin-top: 80px;
    height: 80%;
    width: 50%;
  }
  @media screen and ${device.tablet} {
    margin-top: 0;
    height: 100%;
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  & h1 {
    margin: 0;
    margin-top: 35px;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    color: #fff;
  }

  & #wsp {
    margin-top: 15px;
    width: 80px;
    height: 80px;
  }

  & #logo {
    margin: 0;
    margin-top: 20px;
    width: 78px;
  }

  & > p {
    margin: 0;
    margin-top: 10px;
    font-weight: 400;
    @media screen and ${device.desktop} {
      font-size: 18px;
      line-height: 24px;
    }
    @media screen and ${device.laptop} {
      font-size: 15px;
      line-height: 20px;
    }
    @media screen and ${device.tablet} {
      font-size: 12px;
      line-height: 16px;
    }
    color: #fff;
    text-decoration: underline;
  }
`;

export const Redes = styled.div`
  margin-top: 7px;
  width: 150px;
  height: 45px;
  display: flex;
  justify-content: space-between;

  font-size: 35px;

  & a {
    color: var(--white);
  }
  & a:visited {
    color: var(--white);
    text-decoration: none;
  }
`;

export const Credits = styled.span`
  position: relative;
  margin: auto;
  margin-top: 20px;
  @media screen and ${device.desktop} {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and ${device.laptop} {
    font-size: 12px;
    line-height: 20px;
  }
  @media screen and ${device.tablet} {
    font-size: 10px;
    line-height: 16px;
  }
  color: #fff;
`;
