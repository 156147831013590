import React, { useContext } from "react";
import { GlobalContext } from "../../App";

//? STYLES & ICONS
import { ContainerText, Text } from "./styles/NuestraMisionSC";

function NuestraMision() {
  const context = useContext(GlobalContext);
  const language = context.state.language;

  return (
    <>
      <ContainerText >
        <Text eng={language === "eng" ? true : false}>
          {language === "esp" ? (
            <h1>“Cuestiona todo lo que generalmente se cree obvio.”</h1>
          ) : (
            <h1>“Question everything generally thought to be obvious.”</h1>
          )}
          <h5>Dieter Rams</h5>
        </Text>
      </ContainerText>
    </>
  );
}

export default NuestraMision;
