import styled from "styled-components";
import { device } from "../../styles/device";

export const ContainerSlide = styled.div`
  position: relative;
  max-height: 90vh;
  @media screen and ${device.desktop} {
    width: 100vw;
    height: 830px;
  }
  @media screen and ${device.laptop} {
    width: 100vw;
    height: 600px;
  }
  @media screen and ${device.tablet} {
    width: 100vw;
    height: 500px;
  }
  @media screen and ${device.mobile} {
    width: 100vw;
    max-width: 425px;
    height: 100vw;
    max-height: 415px;
  }

  padding: 0;
  margin: 0;
  box-sizing: border-box;

  overflow: hidden;
`;
