import styled from "styled-components";
import { device } from "../../styles/device";

export const Container = styled.div`
  position: relative;
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 5%;
`;

export const Title = styled.div`
  width: 100%;

  & h1 {
    margin-top: 25px;
    margin-bottom: 9px;

    font-weight: 700;
    @media screen and ${device.desktop} {
      font-size: 60px;
      line-height: 83px;
    }
    @media screen and ${device.laptop} {
      font-size: 50px;
      line-height: 69px;
    }
    @media screen and ${device.tablet} {
      font-size: 40px;
      line-height: 55px;
    }
  }
`;

export const Info = styled.div`
  width: 100%;
  margin-bottom: 18px;

  & h5 {
    margin: 0;

    font-weight: 600;
    @media screen and ${device.desktop} {
      font-size: 18px;
      line-height: 24px;
    }
    @media screen and ${device.laptop} {
      font-size: 15px;
      line-height: 20px;
    }
    @media screen and ${device.tablet} {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const Description = styled.div`
  width: 100%;
  margin-bottom: 25px;

  & p {
    margin: 0;

    font-weight: 400;
    @media screen and ${device.desktop} {
      font-size: 18px;
      line-height: 24px;
    }
    @media screen and ${device.laptop} {
      font-size: 15px;
      line-height: 20px;
    }
    @media screen and ${device.tablet} {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const ClientLogo = styled.div`
  width: 100%;
  margin-bottom: 25px;
  @media screen and ${device.desktop} {
    height: 120px;
  }
  @media screen and ${device.laptop} {
    height: 100px;
  }
  @media screen and ${device.tablet} {
    height: 79px;
  }

  display: flex;
  justify-content: center;

  & img {
    margin-bottom: 21px;
    height: 100%;
  }
`;

export const Images = styled.div`
  width: 100%;
  margin-bottom: 50px;

    @media screen and ${device.desktop} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 15px;

      & img {
        width: 100%;
        height: 400px;
        object-fit: cover;
    }
  }
 
  @media screen and ${device.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    & img {
      margin-top: 13px;
  
      width: 100%;
      height: 230px;
      object-fit: cover;
  }
`;
