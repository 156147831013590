import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100vw;
  top: 45px;

  display: flex;
  flex-direction: column;
`;
