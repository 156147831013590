import styled from "styled-components";

export const SuperContainer = styled.div`
  & .page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  & .content-wrap {
    flex: 1;
  }
`;
