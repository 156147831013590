import styled from "styled-components";

export const Image = styled.img`
  width: 100%;
  height: 100%;
  vertical-align: top;
  object-fit: cover;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SlideContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 87%;
`;

export const Slide = styled.div`
  min-width: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 10;
  position: relative;
`;

export const Controls = styled.div`
  position: absolute;
  top: 88%;
  z-index: 20;
  width: 100%;
  height: 30px;
  pointer-events: none;

  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  pointer-events: all;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  height: 100%;

  font-size: 30px;
  ${props => (props.active ? ` color: var(--darkGreen);` : ` color: var(--darkGrey); cursor: default;`)}

  text-align: center;

  &:hover {
    // color: var(--darkGreen);
  }

  position: absolute;
  ${props => (props.right ? `right: 0;` : `left: 0;`)}
`;

export const DotControls = styled.div`
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 30px;
  pointer-events: none;

  top: 88%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DotButton = styled.button`
  pointer-events: all;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  // border: 1px solid var(--darkGrey);
  cursor: pointer;
  outline: none;

  background: ${props => (props.active ? "var(--darkGreen)" : "var(--lightGrey)")};
  border: ${props => (props.active ? "none" : "1px solid var(--darkGrey)")};

  padding: 0;
  margin: 0 5px;
`;
