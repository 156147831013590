import styled from "styled-components";
import { device } from "../styles/device";

export const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 755px;
  max-height: 90vh;
  @media screen and ${device.desktop} {
    top: 60px;
  }
  @media screen and ${device.tablet} {
    top: 45px;
  }
  z-index: 100;

  background-color: var(--background);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  & p {
    margin: 0;
    font-weight: 700;
    @media screen and ${device.desktop} {
      font-size: 33px;
      line-height: 45px;
    }
    @media screen and ${device.laptop} {
      font-size: 28px;
      line-height: 38px;
    }
    @media screen and ${device.tablet} {
      font-size: 22px;
      line-height: 30px;
    }
    color: #000;

    &:hover {
      color: var(--lightGreen);
    }
  }
`;
