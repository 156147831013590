import React, { useContext } from "react";
import { GlobalContext } from "../../App";
import useWindowDimensions from "../../hooks/getWindowDimensions";

//? COMPONENTS
import Slideshow from "./Slideshow";

//? STYLES & ICONS
import { BackgroundContainer, Container, Title, SlideshowContainer, ImageContainer } from "./styles/SituacionDeUsoSC";

function SituacionDeUso({ images }) {
  const context = useContext(GlobalContext);
  const language = context.state.language;
  const { width } = useWindowDimensions();

  return (
    <BackgroundContainer>
      <Container>
        <Title>
          <Title>{language === "esp" ? <h2>Situación de Uso.</h2> : <h2>Usage Situation.</h2>}</Title>
        </Title>
        {width <= 768 ? (
          <SlideshowContainer>
            <Slideshow images={images} transitionTime={100} autoplay={false} arrowControls={true} dotControls={true} />
          </SlideshowContainer>
        ) : (
          <ImageContainer>
            {images.map((image, index) => (
              <img src={image} alt="" key={index} />
            ))}
          </ImageContainer>
        )}
      </Container>
    </BackgroundContainer>
  );
}

export default SituacionDeUso;
