import React from "react";

//? STYLES & IMAGES
import { Container, Title, Icon } from "./styles/ServicioItemSC";

function ServicioItem({ servicio }) {
  return (
    <Container>
      <Icon src={servicio.icon} alt={servicio.title} />
      <Title>
        <h2>{servicio.title}</h2>
      </Title>
      <p>{servicio.description}</p>
    </Container>
  );
}

export default ServicioItem;
