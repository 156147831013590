import React from "react";

//? STYLES & ICONS
import { Container } from "./styles/ImageContainerSC";

function ImageContainer({ imageURL, title }) {
  return (
    <Container>
      <img src={imageURL} alt={`${title} not available`} />
      <div>
        <p>{title}</p>
      </div>
    </Container>
  );
}

export default ImageContainer;
