import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../App";

//? COMPONENTS
import Intro from "./projects/Intro";
import Prototype from "./projects/Prototype";
import Manual from "./projects/Manual";
import SituacionDeUso from "./projects/SituacionDeUso";
import OtherProjects from "./projects/OtherProjects";

//? STYLES & ICONS
import { Container } from "./styles/ProjectSC";
//*Totem
import inoxidable from "./../images/Inoxidable.png";
import totem1 from "./../images/totem/Totem.jpg";
import totem2 from "./../images/totem/Totem20.jpg";
import totem3 from "./../images/totem/Totem30.jpg";
import totem4 from "./../images/totem/Totem40.jpg";
import totemPrototype10 from "./../images/totem/TotemPrototype10.jpg";
import totemPrototype20 from "./../images/totem/TotemPrototype20.jpg";
import totemPrototype30 from "./../images/totem/TotemPrototype30.jpg";
import totemPrototype40 from "./../images/totem/TotemPrototype40.jpg";
import totemManual10 from "./../images/totem/TotemManual10.jpg";
import totemManual20 from "./../images/totem/TotemManual20.jpg";
import totemManual30 from "./../images/totem/TotemManual30.jpg";
import totemManual40 from "./../images/totem/TotemManual40.jpg";
import totemUso10 from "./../images/totem/TotemUso10.jpg";
import totemUso20 from "./../images/totem/TotemUso20.jpg";
import totemUso30 from "./../images/totem/TotemUso30.jpg";
import totemUso40 from "./../images/totem/TotemUso40.jpg";
//*Chalten
import goosebeack from "./../images/Goosebeak.png";
import chalten1 from "./../images/chalten/Chalten.jpg";
import chalten2 from "./../images/chalten/Chalten20.jpg";
import chalten3 from "./../images/chalten/Chalten30.jpg";
import chalten4 from "./../images/chalten/Chalten40.jpg";
import chaltenPrototype10 from "./../images/chalten/ChaltenPrototype10.jpg";
import chaltenPrototype20 from "./../images/chalten/ChaltenPrototype20.jpg";
import chaltenPrototype30 from "./../images/chalten/ChaltenPrototype30.jpg";
import chaltenManual10 from "./../images/chalten/ChaltenManual10.jpg";
import chaltenManual20 from "./../images/chalten/ChaltenManual20.jpg";
import chaltenManual30 from "./../images/chalten/ChaltenManual30.jpg";
import chaltenManual40 from "./../images/chalten/ChaltenManual40.jpg";
import chaltenUso10 from "./../images/chalten/ChaltenUso10.jpeg";
import chaltenUso20 from "./../images/chalten/ChaltenUso20.jpeg";
import chaltenUso30 from "./../images/chalten/ChaltenUso30.jpeg";
import chaltenUso40 from "./../images/chalten/ChaltenUso40.jpeg";

//?DATA
const totemESP = {
  title: "Totem",
  product: "Dispenser alcohol en gel",
  client: "AC INOXIDABLE",
  description:
    "TOTEM 304 es un higienizador de manos sin contacto. Diseñado para espacios con alto transito de personas en comercios, empresas e instituciones. Ofreciendo una solución segura, fácil, rápida y personalizada para una higiene preventiva",
  clientLogo: inoxidable,
  images: [totem1, totem2, totem3, totem4],
  prototypeImages: [totemPrototype10, totemPrototype20, totemPrototype30, totemPrototype40],
  manual: [totemManual10, totemManual20, totemManual30, totemManual40],
  uso: [totemUso10, totemUso20, totemUso30, totemUso40],
};
const totemENG = {
  title: "Totem",
  product: "Hand sanitizer dispenser",
  client: "AC INOXIDABLE",
  description:
    "TOTEM 304 is a contactless hand sanitizer. Designed for spaces with high traffic of people in shops, companies and institutions. Offering a safe, easy, fast and personalized solution for preventive hygiene",
  clientLogo: inoxidable,
  images: [totem1, totem2, totem3, totem4],
  prototypeImages: [totemPrototype10, totemPrototype20, totemPrototype30, totemPrototype40],
  manual: [totemManual10, totemManual20, totemManual30, totemManual40],
  uso: [totemUso10, totemUso20, totemUso30, totemUso40],
};
const chaltenESP = {
  title: "Chalten",
  product: "Mini Fogonero",
  client: "Goosebeack",
  description:
    "Diseño de un mini forogero para camping realizado en acero inoxidable AISI 304, plegable, encastrable, liviano y fácil de llevar para cocinar donde más te guste. Diseño minimalista y funcional con encastres internos para evitar roturas, estructura en forma de chimenea y troqueles perimetrales para mejorar la combustion.",
  clientLogo: goosebeack,
  images: [chalten1, chalten2, chalten3, chalten4],
  prototypeImages: [chaltenPrototype10, chaltenPrototype20, chaltenPrototype30],
  manual: [chaltenManual10, chaltenManual20, chaltenManual30, chaltenManual40],
  uso: [chaltenUso10, chaltenUso20, chaltenUso30, chaltenUso40],
};

const chaltenENG = {
  title: "Chalten",
  product: "Mini Brazier",
  client: "Goosebeack",
  description:
    "Design of a mini camping brazier made of AISI 304 stainless steel, foldable, built-in, lightweight and easy to carry to cook wherever you like. Minimalist and functional design with internal joints to prevent breakage, chimney-shaped structure and perimeter dies to improve combustion.",
  clientLogo: goosebeack,
  images: [chalten1, chalten2, chalten3, chalten4],
  prototypeImages: [chaltenPrototype10, chaltenPrototype20, chaltenPrototype30],
  manual: [chaltenManual10, chaltenManual20, chaltenManual30, chaltenManual40],
  uso: [chaltenUso10, chaltenUso20, chaltenUso30, chaltenUso40],
};

const otherProjects = [
  { link: "/#/totem", image: totem1 },
  { link: "/#/chalten", image: chalten1 },
];

function Totem({ name }) {
  const context = useContext(GlobalContext);
  const language = context.state.language;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let project;
  if (name === "totem") {
    project = language === "esp" ? totemESP : totemENG;
  } else if (name === "chalten") {
    project = language === "esp" ? chaltenESP : chaltenENG;
  }

  return (
    <Container>
      <Intro
        title={project.title}
        product={project.product}
        client={project.client}
        description={project.description}
        clientLogo={project.clientLogo}
        images={project.images}
      />
      <Prototype images={project.prototypeImages} />
      <Manual images={project.manual} />
      <SituacionDeUso images={project.uso} />
      <OtherProjects images={otherProjects} links />
    </Container>
  );
}

export default Totem;
