import styled from "styled-components";
import { device } from "../../styles/device";

export const ContainerText = styled.div`
  position: relative;
  width: 100vw;
  @media screen and ${device.desktop} {
    height: 700px;
  }
  @media screen and ${device.laptop} {
    height: 550px;
  }
  @media screen and ${device.tablet} {
    height: 450px;
  }
  @media screen and ${device.mobile} {
    height: 350px;
  }
`;

export const Text = styled.div`
  position: absolute;
  top: 8%;
  left: 8%;
  width: 84%;
  height: 84%;

  background-color: rgb(0, 0, 0, 0.9);

  color: var(--background);
  font-weight: 700;
  & h1 {
    width: 92%;
    margin: 10% 4% 0 4%;

    @media screen and ${device.desktop} {
      width: 80%;
      margin: 10% 10% 0 10%;
      font-size: 74px;
      font-weight: bold;
      line-height: 95px;
    }
    @media screen and ${device.laptop} {
      width: 80%;
      margin: 10% 10% 0 10%;
      font-size: 60px;
      font-weight: bold;
      line-height: 82px;
    }
    @media screen and ${device.tablet} {
      width: 80%;
      margin: 10% 10% 0 10%;
      font-size: 45px;
      font-weight: bold;
      line-height: 61px;
    }
    @media screen and ${device.mobile} {
      width: 88%;
      margin: 15% 6% 0 6%;
      font-weight: bold;
      line-height: 49px;
      ${props => (props.eng ? ` font-size: 30px;` : ` font-size: 34px;`)}
    }
    @media screen and (max-width: 400px) {
      margin: 20% 6% 0 6%;
      ${props => (props.eng ? ` font-size: 26px;` : ` font-size: 30px;`)}
    }
    @media screen and (max-width: 350px) {
      margin: 20% 6% 0 6%;
      ${props => (props.eng ? ` font-size: 22px;` : ` font-size: 26px;`)}
    }
  }
  & h5 {
    text-align: right;
    margin-right: 6%;
    @media screen and ${device.desktop} {
      font-size: 40px;
      font-weight: bold;
    }
    @media screen and ${device.laptop} {
      font-size: 32px;
      font-weight: bold;
    }
    @media screen and ${device.tablet} {
      font-size: 24px;
      font-weight: bold;
    }
    @media screen and ${device.mobile} {
      font-size: 18px;
      font-weight: bold;
    }
  }
`;
