import React, { useState, useContext } from "react";
import { GlobalContext } from "../../App";
import useWindowDimensions from "../../hooks/getWindowDimensions";

//? COMPONENTS
import QuienesSomosItem from "./QuienesSomosItem";

//? STYLES & IMAGES
import {
  Container,
  ContainerImage,
  Title,
  Foto,
  ContainerFoto,
  ContainerButtons,
  Logo,
  ContainerButton,
  ContainerImage2,
  LogoBlack,
  Text,
  FirstContainer,
} from "./styles/QuienesSomosSC";
import quienesSomos2 from "../../images/quienesSomos2.jpg";
import logo from "../../images/iso.sinfondo.b.png";
import logoBlack from "../../images/iso.sinfondo.negro.png";

//? DATA
const quienesSomos = [
  { name: "felix", fullName: "Félix Usellini", description1: "Diseñador Industrial", description2: "UX/UI" },
  { name: "marcelo", fullName: "Marcelo Gonzalez", description1: "Diseñador Equipacional", description2: "Artista Plástico" },
  { name: "ignacio", fullName: "Ignacio Gonzalez", description1: "Diseñador de Productos", description2: "Proyectista Mecánico" },
];

const aboutUs = [
  { name: "felix", fullName: "Félix Usellini", description1: "Industrial Designer", description2: "UX/UI" },
  { name: "marcelo", fullName: "Marcelo Gonzalez", description1: "Equipment Designer", description2: "Plastic Artist" },
  { name: "ignacio", fullName: "Ignacio Gonzalez", description1: "Product Designer", description2: "Mechanical Designer" },
];

function QuienesSomos() {
  const context = useContext(GlobalContext);
  const language = context.state.language;
  const { width } = useWindowDimensions();

  const [item, setItem] = useState({
    felix: false,
    marcelo: false,
    ignacio: false,
  });

  const showItem = e => {
    e.preventDefault();
    let newState = { ...item, [e.target.name]: !item[e.target.name] };
    setItem(newState);
  };

  return (
    <Container id={"quienes-somos"}>
      <FirstContainer>
        <ContainerImage>
          <Title eng={language === "eng" ? true : false}>{language === "esp" ? <h3>¿Quiénes somos?</h3> : <h3>About us</h3>}</Title>
        </ContainerImage>
        <ContainerFoto>
          <Foto src={quienesSomos2} alt={"not available"} />
          <ContainerButtons>
            <p></p>
            {language === "esp"
              ? quienesSomos.map((quienSoy, index) => {
                  return (
                    <ContainerButton key={index}>
                      <Logo src={logo} alt="logo not available" name={quienSoy.name} onClick={showItem} />
                      {item[quienSoy.name] && (
                        <QuienesSomosItem
                          name={quienSoy.fullName}
                          description1={quienSoy.description1}
                          description2={quienSoy.description2}
                        />
                      )}
                    </ContainerButton>
                  );
                })
              : aboutUs.map((aboutMe, index) => {
                  return (
                    <ContainerButton key={index}>
                      <Logo src={logo} alt="logo not available" name={aboutMe.name} onClick={showItem} />
                      {item[aboutMe.name] && (
                        <QuienesSomosItem name={aboutMe.fullName} description1={aboutMe.description1} description2={aboutMe.description2} />
                      )}
                    </ContainerButton>
                  );
                })}
          </ContainerButtons>
        </ContainerFoto>
      </FirstContainer>
      <ContainerImage2>
        <LogoBlack src={logoBlack} alt="logo not available" />
        <Text>
          {language === "esp" ? (
            <p>
              ...Nos desafiamos día a día {width < 450 ? <br /> : null}
              para potenciar <br />
              la mejor versión de nosotros {width < 450 ? <br /> : null}mismos...
            </p>
          ) : (
            <p>
              ...We challenge ourselves {width < 450 ? <br /> : null}
              day by day <br />
              to be the best version of {width < 450 ? <br /> : null}
              ourselves...
            </p>
          )}
        </Text>
      </ContainerImage2>
    </Container>
  );
}

export default QuienesSomos;
