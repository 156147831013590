import React from "react";

//? STYLES & IMAGES
import { Container, Name, Description } from "./styles/QuienesSomosItemsSC";

function QuienesSomosItem({ name, description1, description2 }) {
  return (
    <Container>
      <Name>{name}</Name>
      <Description>
        {description1}
        <br /> {description2}
      </Description>
    </Container>
  );
}

export default QuienesSomosItem;
