import styled from "styled-components";

import { loader } from "../../../generalSC";

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
  width: 100vw;
  height: 100vh;

  background-color: rgb(256, 256, 256, 0.9);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopUp = styled.div`
  width: 90vw;
  max-width: 500px;
  height: 150px;

  text-align: center;

  background: var(--darkGreen);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(19.5px);
  border-radius: 15px;
`;

export const Loader = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${loader}
`;

export const Text = styled.p`
  color: var(--white);
  margin: 25px;
  font-size: 20px;
  text-decoration: none;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  margin: 0 5px 25px 5px;
  padding: 5px;

  display: flex;
  flex-direction: row;

  background-color: var(--lightGreen);
  background-color: var(--lightGrey);
  border: 2px solid transparent;
  border-radius: 50%;

  font-size: 30px;

  ${props => (props.cancel ? `color: #CC0000;` : `color: var(--buttonGreen);`)}

  cursor: pointer;
  &:hover {
    background-color: var(--fontColor);
  }
`;
