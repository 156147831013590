import React, { useContext } from "react";
import { GlobalContext } from "../../App";

//? STYLES & IMAGES
import { Container, Title, ClientGrid, ClientGridContainer } from "./styles/ClientesSC";
import durando from "../../images/AntonellaDurandoWhite.png";
import inoxidable from "../../images/InoxidableWhite.png";
import goosebeak from "../../images/GoosebeakWhite.png";
import herracort from "../../images/HerracortWhite.png";

function Clientes() {
  const context = useContext(GlobalContext);
  const language = context.state.language;

  return (
    <Container id={"clientes"}>
      <Title>{language === "esp" ? <h3>CLIENTES</h3> : <h3>CLIENTS</h3>}</Title>
      <ClientGrid>
        <ClientGridContainer>
          <a href="http://acinoxidable.com/" target="_blank" rel="noreferrer">
            <img src={inoxidable} alt="Inoxidable" />
          </a>
        </ClientGridContainer>
        <ClientGridContainer>
          <a href="http://antonelladurando.com/" target="_blank" rel="noreferrer">
            <img src={durando} alt="Antonella Durando" />
          </a>
        </ClientGridContainer>
        <ClientGridContainer>
          <a href="https://instagram.com/goosebeakok?utm_medium=copy_link" target="_blank" rel="noreferrer">
            <img src={goosebeak} alt="Goosebeak" />
          </a>
        </ClientGridContainer>
        <ClientGridContainer>
          <a href="http://www.herracort-sa.com.ar/" target="_blank" rel="noreferrer">
            <img src={herracort} alt="Herracort" />
          </a>
        </ClientGridContainer>
      </ClientGrid>
    </Container>
  );
}

export default Clientes;
