import styled from "styled-components";
import { device } from "../../styles/device";

export const Container = styled.div`
  position: relative;
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 4% 0 5%;
`;

export const Title = styled.div`
  width: 100%;

  & h2 {
    width: 100%;
    margin: 0;
    margin-bottom: 39px;

    color: var(--secondaryFontColor);
    font-weight: 700;
    @media screen and ${device.desktop} {
      font-size: 45px;
      line-height: 62px;
    }
    @media screen and ${device.laptop} {
      font-size: 38px;
      line-height: 51px;
    }
    @media screen and ${device.tablet} {
      font-size: 30px;
      line-height: 41px;
    }

    border-bottom: 1px solid var(--secondaryFontColor);
  }
`;

export const SlideshowContainer = styled.div`
  width: 350px;
  height: 520px;

  padding: 0;
  margin: 0;
  margin-bottom: 30px;
  box-sizing: border-box;

  max-width: 700px;
  overflow: hidden;

  @media screen and (max-width: 350px) {
    width: 300px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;

  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr 1fr;
  // gap: 15px;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  gap: 40px;

  & img {
    height: 400px;
    max-width: 22%;
    object-fit: cover;
  }
`;
