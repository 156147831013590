import styled from "styled-components";
import { device } from "../../styles/device";

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SlideContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
`;

export const Slide = styled.div`
  min-width: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 10;
  position: relative;
`;

export const TextImage2 = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: 46%;

  background-color: rgb(0, 0, 0, 0.9);

  color: var(--background);
  font-weight: 700;
  & h1 {
    line-height: 44px;
    @media screen and ${device.desktop} {
      width: 86%;
      margin: 2% 7% 0 7%;
      font-size: 90px;
      line-height: 115px;
    }
    @media screen and ${device.laptop} {
      width: 86%;
      margin: 4% 7% 0 7%;
      font-size: 60px;
      line-height: 80px;
    }
    @media screen and ${device.tablet} {
      width: 86%;
      margin: 5% 7% 0 7%;
      font-size: 46px;
      line-height: 63px;
    }
  }
`;

export const TextImage3 = styled.div`
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 54%;

  background-color: rgb(0, 0, 0, 0.9);

  color: var(--background);
  font-weight: 700;
  & h1 {
    @media screen and ${device.desktop} {
      width: 90%;
      margin: 5% 5% 0 5%;
      font-size: 76px;
      line-height: 105px;
    }
    @media screen and ${device.laptop} {
      width: 86%;
      margin: 4% 7% 0 7%;
      font-size: 60px;
      line-height: 80px;
    }
    @media screen and ${device.tablet} {
      width: 86%;
      margin: 5% 7% 0 7%;
      font-size: 46px;
      line-height: 63px;
    }
  }
`;

export const TextImage4 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;

  color: #000;
  font-weight: 700;
  & h1 {
    @media screen and ${device.desktop} {
      width: 78%;
      margin: 10% 12% 0 10%;
      font-size: 80px;
      line-height: 115px;
    }
    @media screen and ${device.laptop} {
      width: 80%;
      margin: 13% 10% 0 10%;
      font-size: 60px;
      line-height: 80px;
    }
    @media screen and ${device.tablet} {
      width: 86%;
      margin: 13% 7% 0 7%;
      font-size: 46px;
      line-height: 63px;
    }
  }
`;

export const Image4 = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 39%;
`;

export const TextImage5 = styled.div`
  position: absolute;
  top: 8%;
  left: 8%;
  z-index: 10;
  width: 84%;
  height: 84%;

  background-color: rgb(0, 0, 0, 0.9);

  color: var(--background);
  font-weight: 700;
  & h1 {
    width: 88%;
    margin: 10% 6% 0 6%;
    text-align: center;

    line-height: 45px;
    @media screen and ${device.desktop} {
      ${props => (props.eng ? `font-size:70px;` : `font-size:55px;`)}
      line-height: 115px;
    }
    @media screen and ${device.laptop} {
      ${props => (props.eng ? `font-size:46px; margin: 15% 6% 0 6%;` : `font-size:38px;`)}
      line-height: 80px;
    }
    @media screen and ${device.tablet} {
      ${props => (props.eng ? `font-size:40px; margin: 18% 6% 0 6%;` : `font-size:32px;`)}
      line-height: 63px;
    }
  }
`;

export const Controls = styled.div`
  position: absolute;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const Button = styled.button`
  pointer-events: all;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  padding: 0;
  width: 10%;
  height: 100%;

  font-size: 2.5rem;
  color: var(--lightGreen);

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s ease;
  position: absolute;
  ${props => (props.right ? `right: 0;` : `left: 0;`)}
  

`;

export const DotControls = styled.div`
  position: absolute;
  z-index: 20;
  width: 100%;
  pointer-events: none;

  top: 95%;

  display: flex;
  justify-content: center;
`;

export const DotButton = styled.button`
  pointer-events: all;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--darkGrey);
  cursor: pointer;
  outline: none;

  background: ${props => (props.active ? "var(--lightGreen)" : "var(--lightGrey)")};
  border: ${props => (props.active ? "none" : "1px solid var(--darkGrey)")};

  padding: 0;
  margin: 0 7px;
`;
