import React, { useRef, useEffect, useState, useContext } from "react";
import { useSwipeable } from "react-swipeable";
import { GlobalContext } from "../../App";

//?STYLES & ICONS & IMAGES
import {
  Image,
  Container,
  SlideContainer,
  Slide,
  TextImage2,
  TextImage3,
  TextImage4,
  TextImage5,
  Image4,
  Controls,
  Button,
  DotControls,
  DotButton,
} from "./styles/SlideshowSC";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import image01 from "../../images/carousel10.jpg";
import image02 from "../../images/carousel20.jpg";
import image03 from "../../images/carousel30.jpg";
import image04 from "../../images/carousel40.jpg";
import image05 from "../../images/carousel50.jpg";
import image04logo from "../../images/carousel40logo.png";

function Slideshow({ transitionTime, autoplay, autoplayTime, arrowControls, dotControls }) {
  const slideshow = useRef(null);
  const intervalSlideshow = useRef(null);
  const [imageIndex, setImageIndex] = useState(1);
  const images = [image01, image02, image03, image04, image05];

  const context = useContext(GlobalContext);
  const language = context.state.language;

  //?ARROW CONTROLS
  const next = () => {
    //Comprobamos que el slideshow tenga elementos
    if (slideshow.current.children && slideshow.current.children.length > 0) {
      //Obtenemos el primer elemento del slideshow
      const firstElement = slideshow.current.children[0];
      //Establecemos la transición para el slideshow
      slideshow.current.style.transition = `${transitionTime}ms ease-out all`;
      //Movemos el slideshow hacia la izquierda
      slideshow.current.style.transform = `translateX(-${firstElement.clientWidth}px)`;

      //Añadimos un listener para que cuando termine la transición, se elimine el listener y se ejecute la función transition
      const transition = () => {
        //Reiniciamos la posición del slideshow
        slideshow.current.style.transition = "none";
        slideshow.current.style.transform = "translateX(0px)";
        //Enviamos el primer elemento al final
        slideshow.current.appendChild(firstElement);
        //Eliminamos el listener
        slideshow.current.removeEventListener("transitionend", transition);
      };
      slideshow.current.addEventListener("transitionend", transition);

      //Cambiamos el índice de la imagen
      if (imageIndex === images.length) {
        setImageIndex(1);
      } else {
        setImageIndex(imageIndex + 1);
      }
    }
  };

  const previous = () => {
    if (slideshow.current.children && slideshow.current.children.length > 0) {
      const lastElement = slideshow.current.children[slideshow.current.children.length - 1];
      slideshow.current.insertBefore(lastElement, slideshow.current.firstChild);

      slideshow.current.style.transition = "none";
      slideshow.current.style.transform = `translateX(-${lastElement.clientWidth}px)`;

      setTimeout(() => {
        slideshow.current.style.transition = `${transitionTime}ms ease-out all`;
        slideshow.current.style.transform = "translateX(0px)";
      }, transitionTime / 10);
    }

    if (imageIndex === 1) {
      setImageIndex(images.length);
    } else {
      setImageIndex(imageIndex - 1);
    }
  };

  //?DOT CONTROLS
  const dotControl = dot => {
    if (slideshow.current.children && slideshow.current.children.length > 0) {
      const elements = slideshow.current.children;

      if (dot > imageIndex) {
        slideshow.current.style.transition = `${transitionTime}ms ease-out all`;
        slideshow.current.style.transform = `translateX(-${elements[0].clientWidth * (dot - imageIndex)}px)`;

        const transition = () => {
          slideshow.current.style.transition = "none";
          slideshow.current.style.transform = "translateX(0px)";
          for (let i = 0; i < dot - imageIndex; i++) {
            slideshow.current.appendChild(elements[0]);
          }
          slideshow.current.removeEventListener("transitionend", transition);
        };
        slideshow.current.addEventListener("transitionend", transition);
      } else if (dot < imageIndex) {
        for (let i = 0; i < imageIndex - dot; i++) {
          slideshow.current.insertBefore(elements[elements.length - 1], slideshow.current.firstChild);
        }

        slideshow.current.style.transition = "none";
        slideshow.current.style.transform = `translateX(-${elements[elements.length - 1].clientWidth * (imageIndex - dot)}px)`;

        setTimeout(() => {
          slideshow.current.style.transition = `${transitionTime}ms ease-out all`;
          slideshow.current.style.transform = "translateX(0px)";
        }, transitionTime / 10);
      }
    }
    setImageIndex(dot);
  };

  //?AUTO-PLAY
  useEffect(() => {
    if (autoplay) {
      intervalSlideshow.current = setInterval(() => {
        next();
      }, autoplayTime);

      //Eliminar el intervalo
      slideshow.current.addEventListener("mouseenter", () => {
        clearInterval(intervalSlideshow.current);
      });

      //Reanudamos el intervalo
      slideshow.current.addEventListener("mouseleave", () => {
        intervalSlideshow.current = setInterval(() => {
          next();
        }, autoplayTime);
      });
    }
    //eslint-disable-next-line
  }, []);

  //?SWIPE
  const swipeConfig = {
    delta: 2,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true,
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      next();
    },
    onSwipedRight: () => {
      previous();
    },
    ...swipeConfig,
  });

  return (
    <Container {...swipeHandlers}>
      <SlideContainer ref={slideshow}>
        <Slide>
          <Image src={image01} alt="" />
        </Slide>
        <Slide>
          <Image src={image02} alt="" />
          <TextImage2 eng={language === "eng" ? true : false}>
            {language === "esp" ? (
              <h1>Somos un estudio de Diseño y Desarrollo de Productos.</h1>
            ) : (
              <h1>We are a Product Design & Development studio.</h1>
            )}
          </TextImage2>
        </Slide>
        <Slide>
          <Image src={image03} alt="" />
          <TextImage3 eng={language === "eng" ? true : false}>
            {language === "esp" ? (
              <h1>Partimos de un problema para brindar soluciones funcionales y estéticas.</h1>
            ) : (
              <h1>We start with a problem to provide functional and suitable solutions.</h1>
            )}
          </TextImage3>
        </Slide>
        <Slide>
          <Image src={image04} alt="" />
          <TextImage4 eng={language === "eng" ? true : false}>
            {language === "esp" ? (
              <h1>Ayudamos a PYMES y personas de visión a resolver sus desafíos a traves del diseño.</h1>
            ) : (
              <h1>We help SMEs and people with vision to solve their challenges through design.</h1>
            )}
            <Image4 src={image04logo} alt="" />
          </TextImage4>
        </Slide>
        <Slide>
          <Image src={image05} alt="" />
          <TextImage5 eng={language === "eng" ? true : false}>
            {language === "esp" ? (
              <h1>
                Nuestra misión es diseñar productos que las personas amen, usen y puedan contribuir al crecimiento de nuestros clientes.
              </h1>
            ) : (
              <h1>Our mission is to design products that people love, use and help our customers grow.</h1>
            )}
          </TextImage5>
        </Slide>
      </SlideContainer>
      {arrowControls && (
        <Controls>
          <Button onClick={previous}>
            <IoIosArrowBack />
          </Button>
          <Button right onClick={next}>
            <IoIosArrowForward />
          </Button>
        </Controls>
      )}
      {dotControls && (
        <DotControls>
          {images.map((_image, index) => {
            return <DotButton key={index + 1} active={index + 1 === imageIndex ? true : false} onClick={() => dotControl(index + 1)} />;
          })}
        </DotControls>
      )}
    </Container>
  );
}

export default Slideshow;
