import styled from "styled-components";
import {pulsate} from "./animations/NavbarLogoSC";
import { device } from "../styles/device";

export const Container = styled.div`
  position: fixed;
  top: 0;
  z-index: 200;
  max-height: 100vh;

  & .menu-active {
    @media screen and ${device.desktop} {
      top: 60px;
    }
    @media screen and ${device.tablet} {
      top: 45px;
    }
    transition: 550ms;
  }
  & .menu-inactive {
    top: -755px;
    transition: 850ms;
  }
`;

export const Nav = styled.div`
  width: 100vw;
  top: 0;
  @media screen and ${device.desktop} {
    height: 60px;
  }
  @media screen and ${device.tablet} {
    height: 45px;
  }

  display: grid;
  @media screen and ${device.desktop} {
    grid-template-columns: 60px 1fr 150px 60px;
  }
  @media screen and ${device.tablet} {
    grid-template-columns: 45px 1fr 100px 45px;
  }
  gap: 0;
  grid-auto-flow: row;
`;

export const NavContainer = styled.div`
  height: inherit;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  z-index: 200;

  & a {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .lang-active {
    color: #000;
  }

  & .lang-inactive {
    color: #c2c0c0;
  }
`;

export const Logo = styled.img`
  height: 100%;

  ${pulsate}
`;

export const LanguageSelector = styled.div`
  font: var(--secondaryFont);
  font-weight: 500;
  @media screen and ${device.desktop} {
    font-size: 18px;
    width: 100px;
  }
  @media screen and ${device.tablet} {
    font-size: 12px;
    width: 70px;
  }
  line-height: 18px;
  

  display: flex;
  justify-content: space-between;
  align-items: center;

  & p:first-child {
    border-right: 1px solid #000;
    @media screen and ${device.desktop} {
      padding-right: 13px;
    }
    @media screen and ${device.tablet} {
      padding-right: 11px;
    }
  }

  & p {
    cursor: pointer;
  }
`;

export const MenuButton = styled.div`
  font-size: x-large;
  margin-right: 20px;
  cursor: pointer;
`;
