import React, { useReducer } from "react";
import { Route } from "react-router-dom";

//? COMPONENTS
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Project from "./components/Project";
import Footer from "./components/Footer";

//? STYLES & IMAGES
import { SuperContainer } from "./AppSC";

//? GLOBAL STATE
import { initialState, reducer } from "./state/state";

//? GLOBAL CONTEXT
export const GlobalContext = React.createContext();

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SuperContainer>
      <GlobalContext.Provider value={{ state, dispatch }}>
        <div className="page-container">
          <div className="content-wrap">
            <Route path="/">
              <Navbar />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/totem">
              <Project name="totem" />
            </Route>
            <Route exact path="/chalten">
              <Project name="chalten" />
            </Route>
          </div>
          <Footer />
        </div>
      </GlobalContext.Provider>
    </SuperContainer>
  );
}

export default App;
